import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { Icon } from '../../../common/icon'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage32() {
  const question = {
    title: 'Frage 32 von 35',
    headline: '',
    subline: 'Wie stehst du zu Supervision?',
  }

  const answers = [
    {
      name: 'supervision',
      id: 'in-anspruch',
      value: 'Nehme ich sofort gern in Anspruch.',
      desc: '',
    },
    {
      name: 'supervision',
      id: 'ausprobieren',
      value: 'Würde ich schon mal ausprobieren.',
      desc: '',
    },
    {
      name: 'supervision',
      id: 'brauche-nicht',
      value: 'Brauche ich nicht.',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-yellow-triangles">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="yellow"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="single"
              answers={answers}
              color="green"
              index={32}
            />
          </div>
        </section>

        <MatchingNavigation
          color="yellow"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-33`}
          prev={`${AREA_CARE_PATH}/spielen/frage-31`}
          index={32}
        />

        <Icon position="3" name="brainstorming" />
      </main>
    </>
  )
}
